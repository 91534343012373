import { AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import * as React from "react";

import { Field } from "./Field";

const classNamePrefix = "TextField";

type TextFieldProps = AriaTextFieldOptions<"input"> & { className?: string };

export const TextField = React.forwardRef(
  (props: TextFieldProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      label,
      validationState = props.errorMessage ? "invalid" : "valid",
    } = props;

    const ref = useObjectRef(_ref);
    const { descriptionProps, errorMessageProps, inputProps, labelProps } =
      useTextField({ ...props, validationState }, ref);

    return (
      <Field className={classNames(className, classNamePrefix)}>
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <input ref={ref} {...inputProps} />
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  }
);
