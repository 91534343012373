import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { router } from "../app/services/router";
import { tracking } from "../app/services/tracking";
import { SignUpConfirmAccountForm } from "../features/account/SignUpConfirmAccountForm";
import { SignUpConfirmEmailForm } from "../features/account/SignUpConfirmEmailForm";
import { SignUpConfirmSignUpForm } from "../features/account/SignUpConfirmSignUpForm";
import { SignUpGetAccountWithCharacterCodeForm } from "../features/account/SignUpGetAccountWithCharacterCodeForm";
import { SignUpGetAccountWithEmailForm } from "../features/account/SignUpGetAccountWithEmailForm";
import { SignUpReCaptcha } from "../features/account/SignUpReCaptcha";
import { SignUpResult } from "../features/account/SignUpResult";
import { SignUpState, SignUpStateType } from "../features/account/SignUpState";
import { Dialog } from "../features/shared/Dialog";
import { Separator } from "../features/shared/Separator";

export const SignUpPage = () => {
  const to = router.getTo("authenticationSignUp");

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={to === location.pathname}
      onOpenChange={(open) => {
        if (!open) {
          navigate(router.getTo("home"));
        }
      }}
    >
      <SignUpPageContent
        trigger={document.querySelector(`[href*="${to}"]`) as HTMLAnchorElement}
      />
    </Dialog>
  );
};

type SignUpPageContentProps = { trigger: HTMLAnchorElement };

const SignUpPageContent = (props: SignUpPageContentProps) => {
  const { trigger } = props;

  const [searchParams] = useSearchParams();

  const [state, setState] = React.useState({} as SignUpState);

  const { t } = useTranslation();
  const { meta, title } = t("pages.SignUpPage") as unknown as {
    meta: { title: string };
    title: string;
  };

  React.useEffect(() => {
    const dataLayer = {
      [SignUpStateType.CONFIRM_ACCOUNT]: {
        nom_page: "confirmation_compte",
        virtualpagetitle: "Homepage déconnectée - Confirmation compte",
      },
      [SignUpStateType.CONFIRM_EMAIL]: {
        nom_page: "confirmation_mail",
        virtualpagetitle: "Homepage déconnectée - Confirmation mail",
      },
      [SignUpStateType.CONFIRM_SIGN_UP]: {
        nom_page: "confirmation_envoi_mail",
        virtualpagetitle: "Homepage déconnectée - Confirmation envoi mail",
      },
      [SignUpStateType.GET_ACCOUNT_WITH_CHARACTER_CODE]: {
        nom_page: "saisie_reference_activation",
        virtualpagetitle: "Homepage déconnectée - Saisie reference activation",
      },
      [SignUpStateType.GET_ACCOUNT_WITH_EMAIL]: {
        nom_page: "saisie_donnees_personnelles",
        virtualpagetitle: "Homepage déconnectée - Saisie données personnelles",
      },
      [SignUpStateType.RESULT]: {
        nom_page: "fin",
        virtualpagetitle: "Homepage déconnectée - Fin",
      },
    }[state.step];
    tracking.trackPage({ ...dataLayer, categorie_page: "homepage" });
  }, [state.step]);

  return (
    <Dialog.Content
      meta={meta}
      size={state.step === SignUpStateType.CONFIRM_ACCOUNT ? "large" : "medium"}
      title={title}
      variant="account"
      onCloseAutoFocus={() => trigger.focus()}
      onOpenAutoFocus={() =>
        setState(
          searchParams.get("activationCode") && searchParams.get("username")
            ? {
                step: SignUpStateType.CONFIRM_SIGN_UP,
                session: {
                  code: String(searchParams.get("activationCode")),
                  username: String(searchParams.get("username")),
                },
              }
            : { step: SignUpStateType.GET_ACCOUNT_WITH_EMAIL },
        )
      }
    >
      {(() => {
        if (state.step === SignUpStateType.CONFIRM_ACCOUNT) {
          return (
            <SignUpConfirmAccountForm
              account={state.account}
              onChange={setState}
            />
          );
        }

        if (state.step === SignUpStateType.CONFIRM_EMAIL) {
          return (
            <SignUpConfirmEmailForm
              account={state.account}
              onChange={setState}
            />
          );
        }

        if (state.step === SignUpStateType.CONFIRM_SIGN_UP) {
          return (
            <SignUpConfirmSignUpForm
              session={state.session}
              onChange={setState}
            />
          );
        }

        if (state.step === SignUpStateType.GET_ACCOUNT_WITH_CHARACTER_CODE) {
          return (
            <>
              <SignUpGetAccountWithCharacterCodeForm
                length={14}
                onChange={setState}
              />
              <Separator variant="or" />
              <SignUpGetAccountWithCharacterCodeForm
                length={6}
                onChange={setState}
              />
            </>
          );
        }

        if (state.step === SignUpStateType.GET_ACCOUNT_WITH_EMAIL) {
          return <SignUpGetAccountWithEmailForm onChange={setState} />;
        }

        if (state.step === SignUpStateType.RESULT) {
          return (
            <SignUpResult session={state.session} onChange={setState}>
              {state.message}
            </SignUpResult>
          );
        }

        return null;
      })()}
      <SignUpReCaptcha />
    </Dialog.Content>
  );
};
