import { AriaCheckboxProps, useCheckbox } from "@react-aria/checkbox";
import { AriaFieldProps, useField } from "@react-aria/label";
import { mergeProps, useObjectRef } from "@react-aria/utils";
import { useToggleState } from "@react-stately/toggle";
import classNames from "classnames";
import * as React from "react";

import { Field } from "./Field";

const classNamePrefix = "Checkbox";

type CheckboxProps = AriaCheckboxProps &
  AriaFieldProps & { className?: string };

export const Checkbox = React.forwardRef(
  (props: CheckboxProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      label,
      labelElementType = "span",
      validationState = props.errorMessage ? "invalid" : "valid",
    } = props;

    const state = useToggleState(props);
    const ref = useObjectRef(_ref);
    const { inputProps } = useCheckbox(
      { ...props, children: label, validationState },
      state,
      ref
    );

    const { descriptionProps, errorMessageProps, fieldProps, labelProps } =
      useField({ ...props, labelElementType, validationState });

    return (
      <Field className={classNames(className, classNamePrefix)}>
        <Field.Label {...labelProps}>
          <input ref={ref} {...mergeProps(fieldProps, inputProps)} />
          <span>{label}</span>
        </Field.Label>
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  }
);
