import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HttpStatus } from "../../app/constants/HttpStatus";
import { router } from "../../app/services/router";
import { Button } from "./Button";
import { Result } from "./Result";
import { Spinner } from "./Spinner";

type LoaderProps = { status?: HttpStatus };

export const Loader = (props: LoaderProps) => {
  const { status: rawStatus } = props;

  const { t } = useTranslation();

  if (rawStatus && rawStatus >= 400) {
    const status =
      rawStatus === HttpStatus.NOT_FOUND
        ? HttpStatus.NOT_FOUND
        : HttpStatus.INTERNAL_SERVER_ERROR;
    const { action, text, title } = t("features.shared.Loader", {
      context: status,
    }) as unknown as { action: { text: string }; text: string; title: string };

    return (
      <Result
        action={
          <Button as={Link} to={router.getTo("home")} variant="action">
            {action.text}
          </Button>
        }
        status={status}
        text={text}
        title={title}
      />
    );
  }

  return <Spinner />;
};
