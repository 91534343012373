import classNames from "classnames";
import * as React from "react";

const classNamePrefix = "Form";

type FormRootProps = React.FormHTMLAttributes<HTMLFormElement>;

const FormRoot = React.forwardRef(
  (props: FormRootProps, ref?: React.ForwardedRef<HTMLFormElement>) => {
    const { className, noValidate = true, ...restProps } = props;
    return (
      <form
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        noValidate={noValidate}
      />
    );
  }
);

type FormActionsProps = React.HTMLAttributes<HTMLDivElement>;

const FormActions = React.forwardRef(
  (props: FormActionsProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-actions`)}
      />
    );
  }
);

type FormFieldsetProps = React.FieldsetHTMLAttributes<HTMLFieldSetElement>;

const FormFieldset = React.forwardRef(
  (props: FormFieldsetProps, ref?: React.ForwardedRef<HTMLFieldSetElement>) => {
    const { className, ...restProps } = props;
    return (
      <fieldset
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-fieldset`)}
      />
    );
  }
);

type FormLegendProps = React.HTMLAttributes<HTMLLegendElement> & {
  icon?: React.ReactElement;
};

const FormLegend = React.forwardRef(
  (props: FormLegendProps, ref?: React.ForwardedRef<HTMLLegendElement>) => {
    const { children, className, icon, ...restProps } = props;
    return (
      <legend
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-legend`)}
        data-icon={icon ? "true" : undefined}
      >
        {icon}
        {children}
      </legend>
    );
  }
);

type FormReminderProps = React.HTMLAttributes<HTMLDivElement>;

const FormReminder = React.forwardRef(
  (props: FormReminderProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-reminder`)}
      />
    );
  }
);

export const Form = Object.assign(FormRoot, {
  Actions: FormActions,
  Fieldset: FormFieldset,
  Legend: FormLegend,
  Reminder: FormReminder,
});
