import ReactDOM from "react-dom";

import { Root } from "./Root";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./index.scss";

(() => {
  if (process.env.NODE_ENV === "development") {
    return require("./msw").worker.start();
  }

  return Promise.resolve();
})().then(() => {
  ReactDOM.render(<Root />, document.getElementById("root"));
});

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    (async () => {
      if (registration?.waiting) {
        await registration.unregister();
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.document.addEventListener("visibilitychange", () => {
          if (window.document.visibilityState === "visible") {
            window.location.reload();
          }
        });
        window.location.reload();
      }
    })();
  },
});

reportWebVitals();
