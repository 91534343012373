import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useLazyGetAccessibilityQuery } from "../app/api/public/legals";
import { HttpStatus } from "../app/constants/HttpStatus";
import { router } from "../app/services/router";
import { Dialog } from "../features/shared/Dialog";
import { Loader } from "../features/shared/Loader";
import { Markdown } from "../features/shared/Markdown";

import { ReactComponent as Icon } from "./AccessibilityPageIcon.svg";

export const AccessibilityPage = () => {
  const hash = router.getTo("accessibility");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={hash === location.hash}
      onOpenChange={(open) => {
        if (!open) {
          navigate({ ...location, hash: "" });
        }
      }}
    >
      <AccessibilityPageContent
        trigger={
          document.querySelector(`[href*="${hash}"]`) as HTMLAnchorElement
        }
      />
    </Dialog>
  );
};

type AccessibilityPageContentProps = { trigger: HTMLAnchorElement };

const AccessibilityPageContent = (props: AccessibilityPageContentProps) => {
  const { trigger } = props;

  const [getAccessibility, { currentData: text, error }] =
    useLazyGetAccessibilityQuery();

  const { t } = useTranslation();
  const { meta, title } = t("pages.AccessibilityPage") as unknown as {
    meta: { title: string };
    title: string;
  };

  return (
    <Dialog.Content
      dataLayer={{
        categorie_page: "accessibilite",
        nom_page: "accessibilite",
        virtualpagetitle: "Accessibilité",
      }}
      icon={<Icon />}
      meta={meta}
      title={title}
      onCloseAutoFocus={() => trigger.focus()}
      onOpenAutoFocus={() => getAccessibility(undefined, true)}
    >
      {text ? (
        <Markdown>{text}</Markdown>
      ) : (
        <Loader status={(error as { status: HttpStatus })?.status} />
      )}
    </Dialog.Content>
  );
};
