import React from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";

type FieldControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  render: (controllerProps: {
    field: ControllerRenderProps<TFieldValues, TName> & {
      errorMessage?: string;
    };
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
};

export const FieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FieldControllerProps<TFieldValues, TName>,
) => {
  const { render, ...restProps } = props;
  return (
    <Controller
      {...restProps}
      render={(controllerProps) =>
        render({
          ...controllerProps,
          field: {
            ...controllerProps.field,
            errorMessage: controllerProps.fieldState.error?.message,
          },
        })
      }
    />
  );
};
