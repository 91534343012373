import React from "react";
import { useTranslation } from "react-i18next";

import { useAcceptTermsOfServiceMutation } from "../app/api/private/currentUser";
import { useLazyGetTermsOfServiceQuery } from "../app/api/public/legals";
import { HttpStatus } from "../app/constants/HttpStatus";
import { session } from "../app/services/session";
import { useAppSelector } from "../app/store";
import { selectCurrentUser } from "../app/store/auth";
import { Button } from "../features/shared/Button";
import { ButtonContainer } from "../features/shared/ButtonContainer";
import { Dialog } from "../features/shared/Dialog";
import { Loader } from "../features/shared/Loader";
import { Markdown } from "../features/shared/Markdown";

import { ReactComponent as Icon } from "./AcceptTermsOfServicePageIcon.svg";

export const AcceptTermsOfServicePage = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    <Dialog
      open={!!(currentUser && !currentUser.hasAcceptedTermsOfService)}
      onOpenChange={(open) => {
        if (!open) {
          session.signOut();
        }
      }}
    >
      <AcceptTermsOfServicePageContent />
    </Dialog>
  );
};

const AcceptTermsOfServicePageContent = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);

  const [getTermsOfService, { currentData: text, error }] =
    useLazyGetTermsOfServiceQuery();

  const [acceptTermsOfService] = useAcceptTermsOfServiceMutation();

  const { t } = useTranslation();
  const { action, meta, title } = t(
    "pages.AcceptTermsOfServicePage",
  ) as unknown as {
    action: { label: string; text: string };
    meta: { title: string };
    title: string;
  };

  return (
    <Dialog.Content
      action={
        <ButtonContainer
          action={
            <Button
              disabled={!isScrolled}
              type="button"
              variant="action"
              onClick={() => acceptTermsOfService()}
            >
              {action.text}
            </Button>
          }
          label={action.label}
          variant="accept"
        />
      }
      dataLayer={{
        categorie_page: "acceptation_conditions_generales",
        nom_page: "acceptation_conditions_generales",
        virtualpagetitle: "Acceptation des conditions générales d’utilisation",
      }}
      icon={<Icon />}
      meta={meta}
      title={title}
      onOpenAutoFocus={() => getTermsOfService(undefined, true)}
      onScroll={(event: any) => {
        if (
          Math.ceil(event.target.scrollTop + event.target.clientHeight + 1) >=
          event.target.scrollHeight
        ) {
          setIsScrolled(true);
        }
      }}
    >
      {text ? (
        <Markdown>{text}</Markdown>
      ) : (
        <Loader status={(error as { status: HttpStatus })?.status} />
      )}
    </Dialog.Content>
  );
};
