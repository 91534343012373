import { Separator } from "../shared/Separator";
import { SignUpMarkdown } from "./SignUpMarkdown";
import { SignUpState } from "./SignUpState";

type SignUpMessageProps = {
  children: string;
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpMessage = (props: SignUpMessageProps) => {
  const { children, onChange } = props;
  return (
    <>
      <Separator variant="or" />
      <p>
        <SignUpMarkdown onChange={onChange}>{children}</SignUpMarkdown>
      </p>
    </>
  );
};
