import classNames from "classnames";
import * as React from "react";
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";

const classNamePrefix = "Field";

type FieldRootProps = React.HTMLAttributes<HTMLDivElement>;

const FieldRoot = React.forwardRef(
  (props: FieldRootProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      />
    );
  }
);

type FieldDescriptionProps = React.HTMLAttributes<HTMLDivElement>;

const FieldDescription = React.forwardRef(
  (props: FieldDescriptionProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-description`)}
      />
    );
  }
);

type FieldErrorMessageProps = React.HTMLAttributes<HTMLDivElement>;

const FieldErrorMessage = React.forwardRef(
  (props: FieldErrorMessageProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, role = "alert", ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-errorMessage`)}
        role={role}
      />
    );
  }
);

type FieldIndicatorProps = React.HTMLAttributes<HTMLDivElement>;

const FieldIndicator = React.forwardRef(
  (props: FieldIndicatorProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-indicator`)}
      />
    );
  }
);

type FieldLabelOwnProps = {};
const fieldLabelDefaultElement = "label";

const FieldLabel: PolymorphicForwardRefExoticComponent<
  FieldLabelOwnProps,
  typeof fieldLabelDefaultElement
> = React.forwardRef(
  <TElementType extends React.ElementType = typeof fieldLabelDefaultElement>(
    props: PolymorphicPropsWithoutRef<FieldLabelOwnProps, TElementType>,
    ref?: React.ForwardedRef<HTMLLabelElement>
  ) => {
    const {
      as: Tag = fieldLabelDefaultElement,
      className,
      ...restProps
    } = props;
    return (
      <Tag
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-label`)}
      />
    );
  }
);

export const Field = Object.assign(FieldRoot, {
  Description: FieldDescription,
  ErrorMessage: FieldErrorMessage,
  Indicator: FieldIndicator,
  Label: FieldLabel,
});
